import React from "react";
import {
    Datagrid,
    Edit,
    EditButton,
    List,
    regex,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const formulas = () => `/formulas/1`;

const FormulasToolbar = (props) => (
    <Toolbar {...props}>
        <SaveButton/>
    </Toolbar>
);

const validatePrix = regex(/^[0-9 ,]*$/, "Seulement chiffres et virgule");

export const FormulasList = (props) => {
    const {trackPageView} = useMatomo();
    React.useEffect(() => {
        trackPageView();
    });
    return (

        <Grid
            container
            justify="space-around"
            direction="row"
            alignItems="center"
        >
            <Grid item xs={12} md={5} lg={5}>
                <List
                    {...props}
                    title="Formule 1"
                    bulkActionButtons={false}
                    pagination={false}
                    exporter={false}
                    sort={{field: "id", order: "DESC"}}
                >
                    <Datagrid>
                        <td>
                            {" "}
                            Éditer la formule 1
                            <TextField/>
                        </td>
                        <EditButton/>
                    </Datagrid>
                </List>
            </Grid>
        </Grid>

    );
};

export const FormulasEdit = (props) => {
    const {trackPageView} = useMatomo();
    React.useEffect(() => {
        trackPageView();
    });
    return (

        <Grid
            container
            justify="space-around"
            direction="row"
            alignItems="center"
        >
            <Grid item xs={12} md={5} lg={8}>
                <Edit title="Modifier" {...props} undoable={false}>
                    <SimpleForm
                        toolbar={<FormulasToolbar/>}
                        redirect={formulas}
                    >
                        <TextInput
                            fullWidth={true}
                            resettable
                            multiline
                            label="Élément en français"
                            source="menuFr"
                        />
                        <TextInput
                            fullWidth={true}
                            resettable
                            multiline
                            label="Élément en anglais"
                            source="menuEn"
                        />
                        <TextInput
                            resettable
                            label="Prix de la formule"
                            source="price"
                        />
                    </SimpleForm>
                </Edit>
            </Grid>
        </Grid>

    );
};
